import {
  Redirect, Route, RouteProps, useLocation,
} from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from './rootReducer';
import urls from './utils/urls';
import { clearDirectAccess, clearPreURL, updatePreURL } from './modules/transition';
import { store } from './store';
import { Message } from './utils/messages';
import { transferToNativeMethod } from './utils/transferToNativeMethod';
import { isKonyApp } from './utils/device';

/**
 * 認証済みの場合は表示予定の画面を表示し、それ以外はログイン画面へリダイレクトする。
 * @param props 表示予定の画面
 * @returns 表示結果
 */
const AuthenticatedRoute = (props: RouteProps) => {
  const { loggedIn } = useSelector((state: RootState) => state.user);
  const { directAccess } = useSelector((state: RootState) => state.transition);
  const { preURL } = useSelector((state: RootState) => state.transition);
  const currentURL = document.location.pathname;
  const location = useLocation();
  // url直打ちアクセスかつ、遷移元と異なるページにアクセスした場合にはエラー画面に飛ばす
  if (directAccess && preURL !== currentURL) {
    store.dispatch(clearDirectAccess());
    store.dispatch(clearPreURL());
    // eslint-disable-next-line react/jsx-indent
    return <Redirect to={urls.Z001} />;
  }
  store.dispatch(updatePreURL(currentURL));
  store.dispatch(clearDirectAccess());

  if (loggedIn) {
    return <Route {...props} />;
  }

  if (location.pathname === urls.SPAPPLI2 && isKonyApp()) {
    const transferInfo = [];
    transferInfo.push('4011', Message.wM002());
    transferToNativeMethod('ERROR_LOGOUT', transferInfo);
  }

  if (!directAccess && preURL === '') {
    return <Redirect to={`${urls.LOGIN}?next=${location.pathname.toLocaleLowerCase()}`} />;
  }

  return <Redirect to={urls.LOGIN} />;
};

export default AuthenticatedRoute;
