/**
 * メッセージ管理クラス。
 */
export class Message {
  /**
   * メッセージを返す
   * @returns ただいま混み合っています。しばらくしてから再度お試しください。
   */
  static wM001(): string {
    return 'ただいま混み合っています。しばらくしてから再度お試しください。';
  }

  /**
   * メッセージを返す
   * @returns 一定時間、操作がない為、自動的にログアウトいたしました。再度ログインをお願いします。
   */
  static wM002(): string {
    return '一定時間、操作がない為、自動的にログアウトいたしました。再度ログインをお願いします。';
  }

  /**
   * メッセージを返す
   * @returns 入力してください。
   */
  static vM003(): string {
    return '入力してください。';
  }

  /**
   * メッセージを返す
   * @returns 選択してください。
   */
  static vM004(): string {
    return '選択してください。';
  }

  /**
   * メッセージを返す
   * @params {string} arg1
   * @returns ${arg1}を選択してください。
   */
  static vM005(arg1: string): string {
    return `${arg1}を選択してください。`;
  }

  /**
   * メッセージを返す
   * @params {string} arg1
   * @params {string} arg2
   * @returns ${arg1}から${arg2}の範囲で入力してください。
   */
  static vM006(arg1: string, arg2: string): string {
    return `${arg1}から${arg2}の範囲で入力してください。`;
  }

  /**
   * メッセージを返す
   * @params {string} arg1
   * @returns ${arg1}には数字のみを入力してください。
   */
  static vM007(arg1: string): string {
    return `${arg1}には数字のみを入力してください。`;
  }

  /**
   * メッセージを返す
   * @returns 受付完了メール送付先を選択してください。
   */
  static vM008(): string {
    return '受付完了メール送付先を選択してください。';
  }

  /**
   * メッセージを返す
   * @returns すでにエントリーされています。
   */
  static vM009(): string {
    return 'すでにエントリーされています。';
  }

  /**
   * メッセージを返す
   * @returns 入力された暗証番号と一致していません。再入力してください。
   */
  static vM010(): string {
    return '入力された暗証番号と一致していません。再入力してください。';
  }

  /**
   * メッセージを返す
   * @returns 1,000円以上で入力してください。
   */
  static vM011(): string {
    return '1,000円以上で入力してください。';
  }

  /**
   * メッセージを返す
   * @params {string} arg1
   * @returns ${arg1}で入力してください。
   */
  static vM012(arg1: string): string {
    return `${arg1}で入力してください。`;
  }

  /**
   * メッセージを返す
   * @returns 5枚までアップロード可能です。
   */
  static vM013(): string {
    return '5枚までアップロード可能です。';
  }

  /**
   * メッセージを返す
   * @returns ファイルサイズが6MB以上の画像は提出できません。ファイルを選びなおしてください。
   */
  static vM014(): string {
    return 'ファイルサイズが6MB以上の画像は提出できません。ファイルを選びなおしてください。';
  }

  /**
   * メッセージを返す
   * @params {string} arg1
   * @returns 入力されたパスワードと違います。
   */
  static vM015(arg1: string): string {
    return `7桁の${arg1}を入力してください。`;
  }

  /**
   * メッセージを返す
   * @returns PNG・JPG・GIF形式のファイルを選択してください。
   */
  static vM016(): string {
    return 'PNG・JPG・GIF形式のファイルを選択してください。';
  }

  /**
   * メッセージを返す
   * @returns お預りしている金額以上のお借入れ希望額を入力してください。
   */
  static vM017(): string {
    return 'お預りしている金額以上のお借入れ希望額を入力してください。';
  }

  /**
   * メッセージを返す
   * @returns お借入れ可能額までの金額で入力してください。
   */
  static vM018(): string {
    return 'お借入れ可能額までの金額で入力してください。';
  }

  /**
   * メッセージを返す
   * @returns お利息の合計金額以上で入力してください。
   */
  static vM019(): string {
    return 'お利息の合計金額以上で入力してください。';
  }

  /**
   * メッセージを返す
   * @returns 画像を撮影または選択してください。
   */
  static vM020(): string {
    return '画像を撮影または選択してください。';
  }

  /**
   * メッセージを返す
   * @returns ご返済予定日の確認・変更の前に「ご請求金額の詳細へ」の内容をご確認ください。
   */
  static vM021(): string {
    return 'ご返済予定日の確認・変更の前に「ご請求金額の詳細へ」の内容をご確認ください。';
  }

  /**
   * メッセージを返す
   * @returns パスワードと相違しています。再入力してください。
   */
  static vM022(): string {
    return 'パスワードと相違しています。再入力してください。';
  }

  /**
   * メッセージを返す
   * @params {string} arg1
   * @returns 変更後の${arg1}を入力してください。
   */
  static vM023(arg1: string): string {
    return `変更後の${arg1}を入力してください。`;
  }

  /**
   * メッセージを返す
   * @returns 現在の暗証番号が相違しています。再度入力してください。
   */
  static vM024(): string {
    return '現在の暗証番号が相違しています。再度入力してください。';
  }

  /**
   * メッセージを返す
   * @returns 完済金額（元利合計）以下で入力してください。
   */
  static vM025(): string {
    return '完済金額（元利合計）以下で入力してください。';
  }

  /**
   * メッセージを返す
   * @returns 正しい文字列で入力してください。
   */
  static vM026(): string {
    return '正しい文字列で入力してください。';
  }

  /**
   * メッセージを返す
   * @returns 正しく入力してください。
   */
  static vM027(): string {
    return '正しく入力してください。';
  }

  /**
   * メッセージを返す
   * @returns 入力されたメールアドレスが一致していません。
   */
  static vM028(): string {
    return '入力されたメールアドレスが一致していません。';
  }

  /**
   * メッセージを返す
   * @returns 入力された記号は使用できません。
   */
  static vM029(): string {
    return '入力された記号は使用できません。';
  }

  /**
   * メッセージを返す
   * @returns 入力された暗証番号と一致していません。再入力してください。
   */
  static vM030(): string {
    return '入力された暗証番号と一致していません。再入力してください。';
  }

  /**
   * メッセージを返す
   * @returns ログインIDと相違しています。再入力してください。
   */
  static vM031(): string {
    return 'ログインIDと相違しています。再入力してください。';
  }

  /**
   * メッセージを返す
   * @returns 半角英数字で入力してください。
   */
  static vM032(): string {
    return '半角英数字で入力してください。';
  }

  /**
   * メッセージを返す
   * @params {string} arg1
   * @returns ${arg1}は数字で入力してください。ハイフンは入力不要です。
   */
  static vM033(arg1: string): string {
    return `${arg1}は数字で入力してください。ハイフンは入力不要です。`;
  }

  /**
   * メッセージを返す
   * @returns 半角数字で入力してください。
   */
  static vM034(): string {
    return '半角数字で入力してください。';
  }

  /**
   * メッセージを返す
   * @returns 現在のご返済日と異なる日を選択してください。
   */
  static vM035(): string {
    return '現在のご返済日と異なる日を選択してください。';
  }

  /**
   * メッセージを返す
   * @params {string} arg1
   * @returns ${arg1}万円以上の金額を入力してください。
   */
  static vM036(arg1: string): string {
    return `${arg1}万円以上の金額を入力してください。`;
  }

  /**
   * メッセージを返す
   * @returns 増額可能なご利用限度額以下の金額を入力してください。
   */
  static vM037(): string {
    return '増額可能なご利用限度額以下の金額を入力してください。';
  }

  /**
   * メッセージを返す
   * @returns 自宅電話番号が登録されていません。
   */
  static vM038(): string {
    return '自宅電話番号が登録されていません。';
  }

  /**
   * メッセージを返す
   * @returns メールアドレスが登録されていません。
   */
  static vM039(): string {
    return 'メールアドレスが登録されていません。';
  }

  /**
   * メッセージを返す
   * @returns 完済金額を入力してください。
   */
  static vM040(): string {
    return '完済金額を入力してください。';
  }

  /**
   * メッセージを返す
   * @params {string} arg1
   * @returns ${arg1}を正しく選択してください。
   */
  static vM041(arg1: string): string {
    return `${arg1}を正しく選択してください。`;
  }

  /**
   * メッセージを返す
   * @params {string} arg1
   * @returns 1回から${arg1}の範囲で入力してください。
   */
  static vM042(arg1: string): string {
    return `2回から${arg1}の範囲で入力してください。`;
  }

  /**
   * メッセージを返す
   * @params {string} arg1
   * @returns ${arg1}は1111など同じ数字のみでは登録できません。
   */
  static vM043(arg1: string): string {
    return `${arg1}は1111など同じ数字のみでは登録できません。`;
  }

  /**
   * メッセージを返す
   * @params {string} arg1
   * @returns ${arg1}はカナ姓とカナ名の間にスペースを入力してください。
   */
  static vM044(arg1: string): string {
    return `${arg1}はカナ姓とカナ名の間にスペースを入力してください。`;
  }

  /**
   * メッセージを返す
   * @params {string} arg1
   * @returns ${arg1}は20字以内で入力してください。
   */
  static vM045(arg1: string): string {
    return `${arg1}は20字以内で入力してください。`;
  }

  /**
   * メッセージを返す
   * @returns 正しい生年月日を選択してください。
   */
  static vM046(): string {
    return '正しい生年月日を選択してください。';
  }

  /**
   * メッセージを返す
   * @params {string} arg1
   * @returns ${arg1}に誤りがあります。再入力してください。
   */
  static vM047(arg1: string): string {
    return `${arg1}に誤りがあります。再入力してください。`;
  }

  /**
   * メッセージを返す
   * @params {string} arg1
   * @returns ${arg1}を入力してください。
   */
  static vM048(arg1: string): string {
    return `${arg1}を入力してください。`;
  }

  /**
   * メッセージを返す
   * @returns 半角英字を含めてください。
   */
  static vM049(): string {
    return '半角英字を含めてください。';
  }

  /**
   * メッセージを返す
   * @returns カタカナで入力してください。
   */
  static vM050(): string {
    return 'カタカナで入力してください。';
  }

  /**
   * メッセージを返す
   * @returns 入力した年収を超えています。
   */
  static vM051(): string {
    return '入力した年収を超えています。';
  }

  /**
   * メッセージを返す
   * @returns 入力した月収を下回っています。
   */
  static vM052(): string {
    return '入力した月収を下回っています。';
  }

  /**
   * メッセージを返す
   * @returns 携帯電話番号が登録されていません
   */
  static vM053(): string {
    return '携帯電話番号が登録されていません';
  }

  /**
   * メッセージを返す
   * @returns 勤務先電話番号が登録されていません
   */
  static vM054(): string {
    return '勤務先電話番号が登録されていません';
  }

  /**
   * メッセージを返す
   * @params {string} arg1
   * @params {string} arg2
   * @returns ${arg1}から${arg2}の範囲で入力してください。
   */
  static vM055(arg1: string, arg2: string): string {
    return `${arg1}から${arg2}の範囲で入力してください。`;
  }

  /**
   * メッセージを返す
   * @returns 変更前と同じ暗証番号は登録できません。
   */
  static vM056(): string {
    return '変更前と同じ暗証番号は登録できません。';
  }

  /**
   * メッセージを返す
   * @returns 生年月日と同じ暗証番号は登録できません。
   */
  static vM057(): string {
    return '生年月日と同じ暗証番号は登録できません。';
  }

  /**
   * メッセージを返す
   * @returns パスワードが違います。再入力してください。
   */
  static vM058(): string {
    return 'パスワードが違います。再入力してください。';
  }

  /**
   * メッセージを返す
   * @returns アカウントが登録されていません。
   */
  static vM059(): string {
    return 'アカウントが登録されていません。';
  }

  /**
   * メッセージを返す
   * @returns 英字大文字・小文字、数字、記号のうち、3種類以上を組み合わせて入力してください。
   */
  static vM060(): string {
    return '英字大文字・小文字、数字、記号のうち、3種類以上を組み合わせて入力してください。';
  }

  /**
   * メッセージを返す
   * @returns ログインIDと同じパスワードは設定できません。再入力してください。
   */
  static vM061(): string {
    return 'ログインIDと同じパスワードは設定できません。再入力してください。';
  }

  /**
   * メッセージを返す
   * @returns 同じ文字が3つ以上連続しています。再入力してください。
   */
  static vM062(): string {
    return '同じ文字が3つ以上連続しています。再入力してください。';
  }

  /**
   * メッセージを返す
   * @returns 変更前と同じパスワードは登録できません。
   */
  static vM063(): string {
    return '変更前と同じパスワードは登録できません。';
  }

  /**
   * メッセージを返す
   * @returns 入力された文字は取り扱いできません。
   */
  static vM064(): string {
    return '入力された文字は取り扱いできません。';
  }

  /**
   * メッセージを返す
   * @returns エラーが発生したため処理を完了できませんでした。\n再度操作をやり直してください。
   */
  static wM065(): string {
    return 'エラーが発生したため処理を完了できませんでした。\n再度操作をやり直してください。';
  }

  /**
 * メッセージを返す
 * @returns 0000000は入力できません。
 */
  static vM066(): string {
    return '0000000は入力できません。';
  }

  /**
 * メッセージを返す
 * @params {string} arg1
 * @returns ${arg1}は数字で入力してください。
 */
  static vM067(arg1: string): string {
    return `${arg1}は数字で入力してください。`;
  }
}
