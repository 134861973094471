/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  TopType,
  BankInfoType,
  SupportType,
  ValidationErrorType,
  StateType,
  InformationType,
  CampaignInfoType,
} from './types';

// 初期値
const initialState: StateType = {
  top: {
    会員番号: '',
    勤務先区分: '',
    カード発行状況: '',
    ＴＣ口座登録有無: '',
    ABテストパターン判定: '',
    重要なお知らせの最終更新日時: '',
    お知らせの最終更新日時: '',
    新着メッセージ有無区分: '',
    融資一時停止区分: '',
    sn: '',
    メッセージ重要: '',
    メッセージ重要遷移先機能: '',
    メッセージ重要ボタン名: '',
    口座情報: [],
    処理日時: '',
  },
  selectedBankInfo: {
    口座枝番: '',
    商品名: '',
    無利息期間: '',
    利用可能額: '',
    残高: '',
    次回支払年月日: '',
    自振現状区分: '',
    自振状況区分: '',
    自振登録状況: '',
    毎回額: '',
    返済金額: '',
    メッセージ帯: '',
    メッセージＡ１タイトル: '',
    メッセージＡ１: '',
    メッセージＡ１遷移先機能: '',
    メッセージＡ１ボタン名: '',
    メッセージＡ２タイトル: '',
    メッセージＡ２: '',
    メッセージＡ２遷移先機能: '',
    メッセージＡ２ボタン名: '',
    メッセージＢ: '',
    メッセージＢ遷移先機能: '',
    メッセージＢボタン名: '',
    カード発行区分: '',
    解約区分: '',
  },
  lastLoginDateTime: '',
  support: {
    会員番号: '',
    メッセージＡ１タイトル: '',
    メッセージＡ１: '',
    メッセージＡ１遷移先機能: '',
    メッセージＡ１ボタン名: '',
    メッセージＡ２タイトル: '',
    メッセージＡ２: '',
    メッセージＡ２遷移先機能: '',
    メッセージＡ２ボタン名: '',
    メッセージＡ３タイトル: '',
    メッセージＡ３: '',
    メッセージＡ３遷移先機能: '',
    メッセージＡ３ボタン名: '',
    メッセージＡ４タイトル: '',
    メッセージＡ４: '',
    メッセージＡ４遷移先機能: '',
    メッセージＡ４ボタン名: '',
    メッセージＡ５タイトル: '',
    メッセージＡ５: '',
    メッセージＡ５遷移先機能: '',
    メッセージＡ５ボタン名: '',
    メッセージＡ６タイトル: '',
    メッセージＡ６: '',
    メッセージＡ６遷移先機能: '',
    メッセージＡ６ボタン名: '',
    メッセージＡ７タイトル: '',
    メッセージＡ７: '',
    メッセージＡ７遷移先機能: '',
    メッセージＡ７ボタン名: '',
    メッセージＡ８タイトル: '',
    メッセージＡ８: '',
    メッセージＡ８遷移先機能: '',
    メッセージＡ８ボタン名: '',
  },
  validationError: {
    振込金額: '',
  },
  informations: {
    重要なお知らせ更新日時: '',
    重要なお知らせ内容: [],
    お知らせ更新日時: '',
    お知らせ内容: [],
  },
  bannerInfo: {
    キャンペーン情報: [],
  },
  isSawTutorial: false,
  fromSpagw2: false,
  loginDate4ModalCheck: 'yyyy/mm/dd 00:00:00',
};

const slice = createSlice({
  name: 'top',
  initialState,
  reducers: {
    updateTop: (state: StateType, action: PayloadAction<TopType>) => {
      state.top = action.payload;
    },
    updateCurrentTopInfo: (state: StateType, action: PayloadAction<TopType>) => {
      state.top = action.payload;
    },
    updateBankInfo: (state: StateType, action: PayloadAction<BankInfoType>) => {
      state.selectedBankInfo = action.payload;
    },
    updateSelectedBankInfo: (state: StateType, action: PayloadAction<BankInfoType>) => {
      state.selectedBankInfo = action.payload;
    },
    updateLastLoginDateTime: (state: StateType, action: PayloadAction<string>) => {
      state.lastLoginDateTime = action.payload;
    },
    updateSupport: (state: StateType, action: PayloadAction<SupportType>) => {
      state.support = action.payload;
    },
    updateValidationError: (state: StateType, action: PayloadAction<ValidationErrorType>) => {
      state.validationError = action.payload;
    },
    updateInformations: (state: StateType, action: PayloadAction<InformationType>) => {
      state.informations = action.payload;
    },
    updateIsSawTutorial: (state: StateType, action: PayloadAction<boolean>) => {
      state.isSawTutorial = action.payload;
    },
    updateFromSpagw2: (state: StateType, action: PayloadAction<boolean>) => {
      state.fromSpagw2 = action.payload;
    },
    updateLoginDate4ModalCheck: (state: StateType, action: PayloadAction<string>) => {
      state.loginDate4ModalCheck = action.payload;
    },
    updateCampaignInfo: (state: StateType, action: PayloadAction<Array<CampaignInfoType>>) => {
      state.bannerInfo.キャンペーン情報 = action.payload;
    },
  },
});

export default slice;
