/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useLayoutEffect, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Redirect,
  useLocation,
  Route,
  useHistory,
} from 'react-router-dom';
import { RootState } from '../../rootReducer';
import { AppDispatch } from '../../store';
import userSlice from '../../modules/user';
import xSlice from '../../modules/x-login/module';
import topSlice from '../../modules/t-top/module';
import urls from '../../utils/urls';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import {
  chosenAccessValidator,
  verificationCodeValidator,
} from '../../validators/x-login/login-validator';
import SfApiException from '../../errors/sf-api-exception';
import { handleCommonError, handleJoiValidationError } from '../../utils/handle-errors';
import { isLoading } from '../../modules/app';
import { throwIfJoiValidationError } from '../../utils/validation-helper';
import { updateStylePattern, updateViewPattern } from '../../modules/pattern';

import AwsAuthService from '../../services/aws-auth-service';
import ApiCommon from '../../utils/api-common';
import McsBackendService from '../../services/mcs-backend-service';
import { loadJson } from '../../utils/load-json';
import { LoginQA } from '../../basics/QA/x_loginQA';

import CognitoBackendService from '../../services/cognito-backend-service';
import { removeWarnReloading, warnReloading } from '../../utils/warnReloading';

// X003
const VerificationCodeInputIVR: React.FC = () => {
  const dispatch: AppDispatch = useDispatch();
  const { stylePattren } = useSelector((state: RootState) => state.pattern);
  const location = useLocation();
  const history = useHistory();
  const nextUrl = new URLSearchParams(location.search).get('next');

  const {
    existingMembersValidationError,
    telVerificationCodeValidationError,
    inputPhoneType,
    applicationParams,
    inputSettingItem,
  } = useSelector((state: RootState) => state.xLogin);
  const {
    updateExistingMembersValidationError,
    updateTelVerificationCodeValidationError,
    clearTelVerificationCodeValidationError,
    updateInputPhoneType,
    updateCognitoUserInfo,
    updateWebIdRegistered,
    updateRegisteredEMailAddress,
  } = xSlice.actions;
  const {
    loggedIn,
    deviceInfo,
    tempLoginInfo,
    memberInfo,
  } = useSelector((state: RootState) => state.user);
  const {
    updateLoggedIn,
    updateDeviceInfo,
    updateLoginInfo,
    updateTempLoginInfo,
  } = userSlice.actions;

  const {
    informations,
  } = useSelector((state: RootState) => state.top);

  const {
    updateTop,
    updateBankInfo,
    updateInformations,
    updateLastLoginDateTime,
  } = topSlice.actions;

  const [errorMessage, setErrorMessage] = useState('');

  const [verificationCode, setVerificationCode] = useState('');
  const [view, setView] = useState(false);

  useLayoutEffect(() => {
    if (stylePattren !== 'A') {
      dispatch(updateStylePattern('A'));
    }
  }, []);

  useEffect(() => {
    initiate();
    return () => {
      dispatch(isLoading(false));
    };
  }, []);

  const initiate = async () => {
    await loadInformations();
  };

  const loadInformations = async () => {
    const jsonData = await loadJson('/notice/information.json');
    dispatch(updateInformations(jsonData));
  };

  /**
   * ログインユーザーの情報と端末情報を確認する。
   */
  const loginUser = async () => {
    // 認証コードの送信
    try {
      const apiCommon: ApiCommon = new ApiCommon('X001', 'X-02', urls.X003, '');
      const commonPart = await apiCommon.getLoginCommonPart('', '');
      const dataPart = await apiCommon.getLoginInfoDataPart(tempLoginInfo.tempLoginId, tempLoginInfo.tempPassword);
      if (applicationParams) {
        // アプリ固有 ID が渡されている場合はそれをデータ部にセットする
        const keys = Object.keys(applicationParams);
        for (let i = 0, l = keys.length; i < l; i += 1) {
          // @ts-ignore
          dataPart.データ部[keys[i]] = applicationParams[keys[i]];
        }
      }
      const json = await McsBackendService.request(Object.assign(commonPart, dataPart));
      dispatch(updateDeviceInfo({ ...deviceInfo, distributionId: json.共通部.配信ID, fromUrl: urls.X003 }));
      dispatch(updateViewPattern(json.共通部.ABテストパターン判定));
      dispatch(updateLoginInfo(json.データ部));

      // Cognito に保存されている customLastLoginDateTime を取得しステートに保持
      const lastLoginDate = await getLastLoginDateTime();
      // Cognito のユーザー情報を保持しておく
      const memberNo = await AwsAuthService.getUsername();
      dispatch(updateCognitoUserInfo({
        memberId: memberNo,
        userName: tempLoginInfo.tempLoginId,
        lastLoginDate,
      }));
      // 処理日時を Cognito に保存
      await setLastLoginDateTime(memberNo, json.共通部.処理日時);
      // ログイン失敗回数クリア
      await setFailureCount(memberNo, 0);
      // 一時的に保持していたユーザー ID とパスワードをクリア
      dispatch(updateTempLoginInfo({
        tempLoginId: '',
        tempPassword: '',
      }));

      // ログインステータスを更新しレンダリング
      dispatch(updateLoggedIn(true));

      if (window.ReactNativeWebView) {
        // アプリ用にトップの情報を取得してレスポンスの JSON を postMessage
        const postJson = await nativeTopUserInfo(json.共通部.ABテストパターン判定);
        dispatch(updateTop({
          ...postJson.データ部,
          処理日時: postJson.共通部.処理日時,
        }));
        dispatch(updateBankInfo(postJson.データ部.口座情報[0]));
        window.ReactNativeWebView.postMessage(JSON.stringify(postJson));
        // return postJson;
      } else {
        if (json.データ部.和解客区分 === '1' || json.データ部.ログイン停止区分 === '1') {
          history.push(urls.Q001);
          return;
        }
        history.push(urls.TOP);
      }
    } catch (err) {
      handleErrors(err);
    } finally {
      dispatch(isLoading(false));
    }
  };

  /**
   * 前回ログイン時間を取得する。
   */
  const getLastLoginDateTime = async () => {
    try {
      const res = await CognitoBackendService.request({
        body: {
          username: await AwsAuthService.getUsername(),
        },
        endpoint: '/getUser',
      });

      for (let i = 0; i < res.UserAttributes.length; i += 1) {
        const element = res.UserAttributes[i];
        if (element.Name === process.env.REACT_APP_COGNITO_CUSTOM_ATTRIBUTE_1) {
          dispatch(updateLastLoginDateTime(element.Value));
          return element.Value;
        }
      }
    } catch (err) {
      handleErrors(err);
    }

    return '';
  };

  /**
   * cognitoの前回ログイン時間を更新する。
   */
  const setLastLoginDateTime = async (memberId: string, time: string) => {
    try {
      await CognitoBackendService.request({
        body: {
          username: memberId,
          customAttributeValue: time || '',
          customAttribute: process.env.REACT_APP_COGNITO_CUSTOM_ATTRIBUTE_1,
        },
        endpoint: '/changeCustomAttribute',
      });
    } catch (err) {
      handleErrors(err);
    }
  };

  /**
   * Cognitoのログイン失敗回数をセットする
   */
  const setFailureCount = async (memberId: string, failureCount: number) => {
    try {
      await CognitoBackendService.request({
        body: {
          username: memberId,
          customAttributeValue: failureCount.toString(),
          customAttribute: process.env.REACT_APP_COGNITO_CUSTOM_ATTRIBUTE_2,
        },
        endpoint: '/changeCustomAttribute',
      });
    } catch (err) {
      handleErrors(err);
    }
    return failureCount;
  };

  /**
   * 【認証コード入力_IVR画面】発信ボタン処理
   */
  const handleClickIVROutgoing = async () => {
    if (!IVRConfirmValidation) {
      return;
    }
    dispatch(isLoading(true));
    try {
      const apiCommon: ApiCommon = new ApiCommon('X003', 'X-02', urls.X003, '');
      const commonPart = await apiCommon.getLoginCommonPart('', deviceInfo.distributionId);
      const dataPart = {
        データ部: {
          会員番号: '',
          希望連絡先: inputPhoneType,
        },
      };
      await McsBackendService.request(Object.assign(commonPart, dataPart));
      // history.push(urls.TOP);
    } catch (err) {
      handleErrors(err);
    } finally {
      dispatch(isLoading(false));
    }
  };

  const IVRConfirmValidation = () => {
    dispatch(clearTelVerificationCodeValidationError());

    // バリデーション
    const validParams: { ご希望の連絡先: string } = {
      ご希望の連絡先: inputPhoneType,
    };
    try {
      throwIfJoiValidationError(chosenAccessValidator(validParams, { abortEarly: false }));
    } catch (err) {
      handleValidationError(err);
      dispatch(isLoading(false));
      setView(false);
      return false;
    }
    return true;
  };

  /**
   * 【認証コード入力_IVR画面】送信ボタン処理
   */
  const handleClickIVRSend = async () => {
    dispatch(isLoading(true));
    setView(true);

    // バリデーション
    if (!confirmValidation()) {
      return;
    }

    try {
      warnReloading();
      const apiCommon: ApiCommon = new ApiCommon('X003', 'X-03', urls.X003, '');
      const commonPart = await apiCommon.getLoginCommonPart(verificationCode, deviceInfo.distributionId);
      const dataPart = {
        データ部: {
          会員番号: '',
          メールアドレス: '',
        },
      };
      const json = await McsBackendService.request(Object.assign(commonPart, dataPart));
      dispatch(updateWebIdRegistered(json.データ部.メールアドレス.toLowerCase()));

      // 遷移元によって遷移先分岐
      if (deviceInfo.fromUrl === urls.X009) {
        dispatch(updateRegisteredEMailAddress(json.データ部.メールアドレス));
        history.push(urls.X010);
      } else if (deviceInfo.fromUrl === urls.X013) {
        dispatch(updateRegisteredEMailAddress(json.データ部.メールアドレス));
        // 希望設定項目によって遷移先を変更する
        if (inputSettingItem === '1') {
          // ID
          history.push(urls.X014);
        } else if (inputSettingItem === '2') {
          // パスワード
          history.push(urls.X015);
        } else {
          // ID/パスワード
          history.push(urls.X016);
        }
      } else {
        // 再度ログインを実施
        await loginUser();
      }
    } catch (err) {
      handleErrors(err);
    } finally {
      removeWarnReloading();
      dispatch(isLoading(false));
    }
  };

  /**
   * 【認証コード入力_IVR画面】認証コードが届かないお客様ボタン処理
   */
  const handleClicIVRNotReceive = async (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.preventDefault();
    dispatch(isLoading(true));
    try {
      const apiCommon: ApiCommon = new ApiCommon('X003', 'X-04', urls.X003, '');
      const commonPart = await apiCommon.getLoginCommonPart('', '');
      const dataPart = {
        データ部: {
          会員番号: memberInfo.memberNo,
          代表電話番号: '',
        },
      };
      await McsBackendService.request(Object.assign(commonPart, dataPart));
      history.push(urls.X004);
    } catch (err) {
      handleErrors(err);
    } finally {
      dispatch(isLoading(false));
    }
  };

  /**
   * エラー用ハンドラー
   * @param err Error
   */
  const handleErrors = (err: any) => {
    if (err instanceof SfApiException) {
      switch (err.code) {
        case 'WAG20064':
        case 'WAG20066':
          history.push(urls.X005);
          break;
        // メンテナンス中
        case 'ESGXX001':
          history.push(urls.X007, { message: err.共通部.エラーメッセージ });
          break;
        default:
          setErrorMessage(err.message);
      }
    } else {
      handleCommonError(err, {
        validationErrorState: existingMembersValidationError,
        updateValidationError: updateExistingMembersValidationError,
      });
      setErrorMessage(err.message);
    }
  };

  const handleValidationError = (err: any) => {
    handleJoiValidationError(err, {
      validationErrorState: telVerificationCodeValidationError,
      updateValidationError: updateTelVerificationCodeValidationError,
    });
  };

  /**
   * フォーカスを外した際のバリデーション
   * @param name element.name
   * @param value element.value
   */
  const onBlurValid = async (name: string) => {
    dispatch(updateExistingMembersValidationError({ ...existingMembersValidationError, [name]: '' }));
    setErrorMessage('');
    try {
      switch (name) {
        default:
          break;
      }
    } catch (err: any) {
      handleValidationError(err);
    }
  };

  const confirmValidation = () => {
    dispatch(clearTelVerificationCodeValidationError());

    // バリデーション
    const validParams: { 認証コード: string } = {
      認証コード: verificationCode,
    };
    try {
      throwIfJoiValidationError(verificationCodeValidator(validParams, { abortEarly: false }));
    } catch (err) {
      handleValidationError(err);
      dispatch(isLoading(false));
      setView(false);
      return false;
    }
    return true;
  };

  /**
   * フォーカスを外した際のバリデーション
   * @param name element.name
   * @param value element.value
   */
  const onBlurValidate = async (name: string) => {
    dispatch(updateTelVerificationCodeValidationError({ ...telVerificationCodeValidationError, [name]: '' }));
    setErrorMessage('');
    try {
      switch (name) {
        case '認証コード':
          throwIfJoiValidationError(verificationCodeValidator({
            [name]: verificationCode,
          }));
          break;
        default:
          break;
      }
    } catch (err: any) {
      handleValidationError(err);
    }
  };

  const nativeTopUserInfo = async (viewPattern: string) => {
    try {
      dispatch(isLoading(true));
      const apiCommon: ApiCommon = new ApiCommon('T001', 'T-10', urls.X003, viewPattern);
      const commonPart = await apiCommon.getCommonPart();
      let dataPart;
      if (informations !== null) {
        dataPart = await apiCommon.getUserInfoNativeDataPart(informations.重要なお知らせ更新日時, informations.お知らせ更新日時);
      } else {
        dataPart = await apiCommon.getUserInfoNativeDataPart('', '');
      }
      return await McsBackendService.request(Object.assign(commonPart, dataPart));
    } catch (err) {
      //      handleErrors(err);
      return '';
    } finally {
      dispatch(isLoading(false));
    }
  };

  if (window.ReactNativeWebView) {
    return (
      <>
        <Route component={Header} />
        <main className="l-main">
          <section>
            <div className="blockMain mb05gd">
              <div className="blockNarrow">
                <div className="ttlH1 mtFLAT">
                  <p className="fsXS mbFLAT mtFLAT font-black">会員ログイン</p>
                  <h1>電話（音声）<br className="forSP" />認証コード入力</h1>
                </div>
                <p className="mtFLAT mbFLAT">
                  ご登録の電話番号にお電話します。電話でお伝えする認証コード4桁を入力し、「送信」ボタンを押してください。<br />
                  なお、認証コードの有効期限は1分です。
                </p>
              </div>
            </div>
            <div className="blockSub mb05gd">
              <div className="blockNarrow blockNarrow--S">
                <p className="label fs2XS mbXS mtFLAT">ご希望の連絡先</p>
                <div className="form__radioList mb05gd">
                  <ul>
                    <li>
                      <input
                        type="radio"
                        className="form__radioList__input"
                        id="radio-01"
                        name="携帯電話"
                        value="3"
                        checked={inputPhoneType === '3'}
                        onChange={(e) => dispatch(updateInputPhoneType(e.target.value))}
                        onBlur={(e) => onBlurValid(e.target.name)}
                      />
                      <label htmlFor="radio-01" className="form__radioList__label">携帯電話</label>
                    </li>
                    <li>
                      <input
                        type="radio"
                        className="form__radioList__input"
                        id="radio-02"
                        name="自宅"
                        value="1"
                        checked={inputPhoneType === '1'}
                        onChange={(e) => dispatch(updateInputPhoneType(e.target.value))}
                        onBlur={(e) => onBlurValid(e.target.name)}
                      />
                      <label htmlFor="radio-02" className="form__radioList__label">自宅</label>
                    </li>
                  </ul>
                  <p className="error-comment">{telVerificationCodeValidationError.ご希望の連絡先}</p>
                </div>
                <div className="mbXL flexC">
                  <button className="btn btn--cv" onClick={handleClickIVROutgoing}>発信</button>
                </div>
                <div className="form__inputBox aC">
                  <div className={`form__inputBox__outer ${verificationCode !== '' ? 'form__inputBox__outer--valid' : ''}`}>
                    <input
                      type="text"
                      minLength={4}
                      maxLength={4}
                      name="認証コード"
                      onChange={(e) => setVerificationCode(e.target.value)}
                      onBlur={(e) => onBlurValidate(e.target.name)}
                    />
                    <span className="form__inputBox__placeholder">認証コード（4桁）</span>
                  </div>
                </div>
                <div className="flexC mb05gd">
                  <p className="error-comment error-comment-center">{telVerificationCodeValidationError.認証コード || errorMessage}</p>
                </div>
                <div className="mb05gd flexC">
                  <button className="btn btn--cv" onClick={handleClickIVRSend}>送信</button>
                </div>
                <div className="aR mbFLAT">
                  <a className="link linkArrowRight" href="#####" onClick={handleClicIVRNotReceive}>認証コードが届かないお客さま</a>
                </div>
              </div>
            </div>
            <div className="blockSub mb05gd">
              <div className="ttlH2">
                <h2>よくあるご質問</h2>
              </div>
              <LoginQA />
            </div>
          </section>
        </main>
        <Route component={Footer} />
      </>
    );
  }

  return (
    <>
      <Route component={Header} />
      <main className="l-main">
        {loggedIn
          ? <Redirect push to={nextUrl || urls.TOP} />
          : (
            <section>
              <div className="blockMain mb05gd">
                <div className="blockNarrow">
                  <div className="ttlH1 mtFLAT">
                    <p className="fsXS mbFLAT mtFLAT font-black">会員ログイン</p>
                    <h1>電話（音声）<br className="forSP" />認証コード入力</h1>
                  </div>
                  <p className="mtFLAT mbFLAT">
                    ご登録の電話番号にお電話します。電話でお伝えする認証コード4桁を入力し、「送信」ボタンを押してください。<br />
                    なお、認証コードの有効期限は1分です。
                  </p>
                </div>
              </div>
              <div className="blockSub mb05gd">
                <div className="blockNarrow blockNarrow--S">
                  <p className="label fs2XS mbXS mtFLAT">ご希望の連絡先</p>
                  <div className="form__radioList mb05gd">
                    <ul>
                      <li>
                        <input
                          type="radio"
                          className="form__radioList__input"
                          id="radio-01"
                          name="携帯電話"
                          value="3"
                          checked={inputPhoneType === '3'}
                          onChange={(e) => dispatch(updateInputPhoneType(e.target.value))}
                          onBlur={(e) => onBlurValid(e.target.name)}
                        />
                        <label htmlFor="radio-01" className="form__radioList__label">携帯電話</label>
                      </li>
                      <li>
                        <input
                          type="radio"
                          className="form__radioList__input"
                          id="radio-02"
                          name="自宅"
                          value="1"
                          checked={inputPhoneType === '1'}
                          onChange={(e) => dispatch(updateInputPhoneType(e.target.value))}
                          onBlur={(e) => onBlurValid(e.target.name)}
                        />
                        <label htmlFor="radio-02" className="form__radioList__label">自宅</label>
                      </li>
                    </ul>
                    <p className="error-comment">{telVerificationCodeValidationError.ご希望の連絡先}</p>
                  </div>
                  <div className="mbXL flexC">
                    <button className="btn btn--cv" onClick={handleClickIVROutgoing}>発信</button>
                  </div>
                  <div className="form__inputBox aC">
                    <div className={`form__inputBox__outer ${verificationCode !== '' ? 'form__inputBox__outer--valid' : ''}`}>
                      <input
                        type="text"
                        minLength={4}
                        maxLength={4}
                        name="認証コード"
                        onChange={(e) => setVerificationCode(e.target.value)}
                        onBlur={(e) => onBlurValidate(e.target.name)}
                      />
                      <span className="form__inputBox__placeholder">認証コード（4桁）</span>
                    </div>
                  </div>
                  <div className="flexC mb05gd">
                    <p className="error-comment error-comment-center">{telVerificationCodeValidationError.認証コード || errorMessage}</p>
                  </div>
                  <div className="mb05gd flexC">
                    <button className="btn btn--cv" onClick={handleClickIVRSend}>送信</button>
                  </div>
                  <div className="aR mbFLAT">
                    <a className="link linkArrowRight" href="#####" onClick={handleClicIVRNotReceive}>認証コードが届かないお客さま</a>
                  </div>
                </div>
              </div>
              <div className="blockSub mb05gd">
                <div className="ttlH2">
                  <h2>よくあるご質問</h2>
                </div>
                <LoginQA />
              </div>
            </section>
          )}
      </main>
      <Route component={Footer} />
    </>
  );
};

export default VerificationCodeInputIVR;
