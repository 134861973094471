/**
 * 各ページのURLを定義。
 */
export const urls = {
  LOGIN: '/',
  X002: '/x002',
  X003: '/x003',
  X004: '/x004',
  X005: '/x005',
  X006: '/x006',
  X007: '/x007',
  X008: '/x008',
  X009: '/x009',
  X010: '/x010',
  X011: '/x011',
  X012: '/x012',
  X013: '/x013',
  X014: '/x014',
  X015: '/x015',
  X016: '/x016',
  X017: '/x017',
  SPAPPLI: '/spagw1',
  SPAPPLI2: '/spagw2',
  TOP: '/t001',
  T005: '/t005',
  A001: '/a001',
  A002: '/a002',
  B001: '/b001',
  B002: '/b002',
  B003: '/b003',
  B004: '/b004',
  B005: '/b005',
  B006: '/b006',
  B008: '/b008',
  B009: '/b009',
  B010: '/b010',
  B012: '/b012',
  B013: '/b013',
  B015: '/b015',
  C001: '/c001',
  C002: '/c002',
  C004: '/c004',
  C005: '/c005',
  C006: '/c006',
  C007: '/c007',
  C010: '/c010',
  C011: '/c011',
  C012: '/c012',
  D001: '/d001',
  D002: '/d002',
  D003: '/d003',
  D004: '/d004',
  D005: '/d005',
  D006: '/d006',
  D007: '/d007',
  D008: '/d008',
  D009: '/d009',
  E001: '/e001',
  E002: '/e002',
  E003: '/e003',
  E004: '/e004',
  E005: '/e005',
  E007: '/e007',
  E008: '/e008',
  E009: '/e009',
  E016: '/e016',
  F001: '/f001',
  F002: '/f002',
  F003: '/f003',
  F004: '/f004',
  G001: '/g001',
  G002: '/g002',
  G003: '/g003',
  H001: '/h001',
  H002: '/h002',
  H003: '/h003',
  H004: '/h004',
  H005: '/h005',
  H006: '/h006',
  H007: '/h007',
  H008: '/h008',
  H009: '/h009',
  H010: '/h010',
  H012: '/h012',
  H013: '/h013',
  H014: '/h014',
  H015: '/h015',
  H016: '/h016',
  H017: '/h017',
  H018: '/h018',
  H019: '/h019',
  H020: '/h020',
  H021: '/h021',
  H022: '/h022',
  H023: '/h023',
  H024: '/h024',
  H025: '/h025',
  H026: '/h026',
  H027: '/h027',
  H028: '/h028',
  H029: '/h029',
  H030: '/h030',
  H031: '/h031',
  H032: '/h032',
  H033: '/h033',
  H036: '/h036',
  I001: '/i001',
  I002: '/i002',
  I003: '/i003',
  I004: '/i004',
  I006: '/i006',
  I007: '/i007',
  J001: '/j001',
  J002: '/j002',
  J003: '/j003',
  J004: '/j004',
  J005: '/j005',
  J006: '/j006',
  J007d: '/j007d',
  J008d: '/j008d',
  J012: '/j012',
  J013: '/j013',
  J014: '/j014',
  J015: '/j015',
  J016: '/j016',
  J017: '/j017',
  K001: '/k001',
  K002: '/k002',
  K003: '/k003',
  K004: '/k004',
  K005: '/k005',
  K006: '/k006',
  K007: '/k007',
  K008: '/k008',
  K009: '/k009',
  K021: '/k021',
  K024: '/k024',
  K025: '/k025',
  K026: '/k026',
  K027: '/k027',
  K028: '/k028',
  K029: '/k029',
  K030: '/k030',
  K031: '/k031',
  K032: '/k032',
  L001: '/l001',
  L002: '/l002',
  M001: '/m001',
  M002: '/m002',
  M004: '/m004',
  M005: '/m005',
  M006: '/m006',
  O001: '/o001',
  O002: '/o002',
  O003: '/o003',
  O004: '/o004',
  O005: '/o005',
  O006: '/o006',
  O007: '/o007',
  P002: '/p002',
  P003: '/p003',
  P004: '/p004',
  P005: '/p005',
  P006: '/p006',
  Q001: '/q001',
  S001: '/s001',
  S002: '/s002',
  S003: '/s003',
  S004: '/s004',
  S005: '/s005',
  S006: '/s006',
  W001: '/w001',
  Z001: '/z001',
  Z003: '/z003',
  TT001: '/tt001',
  TT002: '/tt002',
  TT003: '/tt003',
  TT005: '/tt005',
};

export default urls;
