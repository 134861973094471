import { AnyAction, combineReducers } from '@reduxjs/toolkit';
import userSlice from './modules/user';
import topSlice from './modules/t-top/module';
import aSlice from './modules/a-transaction-history/module';
import bSlice from './modules/b-repayment-plan/module';
import cSlice from './modules/c-contract-date/module';
import dSlice from './modules/d-web-details/module';
import eSlice from './modules/e-loan-application/module';
import fSlice from './modules/f-loan-application-inquiry/module';
import gSlice from './modules/g-account-registration/module';
import hSlice from './modules/h-limit-increase/module';
import iSlice from './modules/i-web-repayment/module';
import jSlice from './modules/j-account-transfer/module';
import kSlice from './modules/k-customer-attributes/module';
import lSlice from './modules/l-income-certificate/module';
import mSlice from './modules/m-change-account-info/module';
import oslice from './modules/o-card-suspension/module';
import pSlice from './modules/p-inquiry/module';
import qSlice from './modules/q-contract-details-information/module';
import sSlice from './modules/s-deposit/module';
import wSlice from './modules/w-account-information/module';
import xSlice from './modules/x-login/module';
import appSlice from './modules/app';
import patternSlice from './modules/pattern';
import specialmoveSlice from './modules/specialmove';
import transitionSlice from './modules/transition';

import urls from './utils/urls';

/**
 * Reduxで管理するステートを定義。
 */
const appReducer = combineReducers({
  user: userSlice.reducer,
  top: topSlice.reducer,
  aTransactionHistory: aSlice.reducer,
  bRepaymentPlan: bSlice.reducer,
  cContractDate: cSlice.reducer,
  dWebDetails: dSlice.reducer,
  eLoanApplication: eSlice.reducer,
  fLoanApplicationInquiry: fSlice.reducer,
  gAccountRegistration: gSlice.reducer,
  hLimitIncrease: hSlice.reducer,
  iWebRepayment: iSlice.reducer,
  jAccountTransfer: jSlice.reducer,
  kCustomerAttributes: kSlice.reducer,
  lIncomeCertificate: lSlice.reducer,
  mChangeAccountInfo: mSlice.reducer,
  ocardSuspension: oslice.reducer,
  pInquiry: pSlice.reducer,
  qContractDetailsInfo: qSlice.reducer,
  sDeposit: sSlice.reducer,
  wAccountInformation: wSlice.reducer,
  xLogin: xSlice.reducer,
  app: appSlice.reducer,
  pattern: patternSlice.reducer,
  specialmove: specialmoveSlice.reducer,
  transition: transitionSlice.reducer,
});

/**
 * ステート変更時に実行される関数。
 * @param state ステート
 * @param action アクション
 * @returns ステート管理情報
 */
const rootReducer = (state: any, action: AnyAction) => {
  const isBeforeLogin = (document.location.pathname === urls.LOGIN) // ログイン画面
    || (document.location.pathname.indexOf('/x') >= 0); // ログイン前に遷移できる画面
  // ログイン前なら以下は実行しない
  if (isBeforeLogin) {
    return appReducer(state, action);
  }

  if (action.type === 'user/updateLoggedIn' && action.payload === false) {
    // ログアウト時にステートを全て初期化
    return appReducer(undefined, action);
  }
  return appReducer(state, action);
};

export type RootState = ReturnType<typeof rootReducer>

export default rootReducer;
