/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  qType,
  BankInfoType,
  BasicContractInfoType,
  keihenContractInfoType,
  StateType,
} from './types';

// 初期値
const initialState: StateType = {
  qData: {
    会員番号: '',
    口座情報: [],
    処理日時: '',
  },
  qBankInfo: {
    口座枝番: '',
    商品名: '',
    WEB明細サービス非表示フラグ: '',
    前月明細ダウンロード可能フラグ: '',
    基本契約書ダウンロード可能フラグ: '',
    変更契約書ダウンロード可能フラグ: '',
    基本契約＿契約変更履歴: [],
    変更契約＿契約変更履歴: [],
  },
  qBasicContractInfo: {
    連番: '',
    契約年月日: '',
  },
  keihenContractInfo: {
    連番: '',
    契約年月日: '',
  },
};

const slice = createSlice({
  name: 'q',
  initialState,
  reducers: {
    updateQdata: (state: StateType, action: PayloadAction<qType>) => {
      state.qData = action.payload;
    },
    updateQbankInfo: (state: StateType, action: PayloadAction<BankInfoType>) => {
      state.qBankInfo = action.payload;
    },
    updateQBasicContractInfo: (state: StateType, action: PayloadAction<BasicContractInfoType>) => {
      state.qBasicContractInfo = action.payload;
    },
    updateKeihenContractInfo: (state: StateType, action: PayloadAction<keihenContractInfoType>) => {
      state.keihenContractInfo = action.payload;
    },
    clearContractDetailsInformation: (state: StateType) => {
      state.qData = initialState.qData;
      state.qBankInfo = initialState.qBankInfo;
      state.qBasicContractInfo = initialState.qBasicContractInfo;
      state.keihenContractInfo = initialState.keihenContractInfo;
    },
  },
});

export default slice;
