import React, {
  useLayoutEffect,
  useRef,
  useEffect,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Redirect,
  useLocation,
  useHistory,
  Link,
} from 'react-router-dom';
import {
  AuthenticationDetails,
  CognitoUser,
  CognitoUserSession,
} from 'amazon-cognito-identity-js';
import $ from 'jquery';

import Footer from '../../components/Footer';
import { RootState } from '../../rootReducer';
import { AppDispatch } from '../../store';
import { isLoading } from '../../modules/app';
import { updateStylePattern, updateViewPattern } from '../../modules/pattern';
import userSlice, { updateTempLoginInfo } from '../../modules/user';
import xSlice from '../../modules/x-login/module';
import topSlice from '../../modules/t-top/module';
import AwsAuthService from '../../services/aws-auth-service';
import McsBackendService from '../../services/mcs-backend-service';
import {
  idValidator,
  passwordValidator,
  allLoginValidator,
} from '../../validators/x-login/login-validator';
import ApiCommon from '../../utils/api-common';
import { handleJoiValidationError } from '../../utils/handle-errors';
import { Message } from '../../utils/messages';
import urls from '../../utils/urls';
import { useUnmountRef, useSafeState } from '../../utils/safe-state';
import { throwIfJoiValidationError } from '../../utils/validation-helper';
import SfApiException from '../../errors/sf-api-exception';
import CognitoBackendService from '../../services/cognito-backend-service';
import { createLinkUrlGeneral } from '../../utils/create-link-url';
import LoginHeader from '../../components/LoginHeader';
import { isAllowedUrl } from '../../utils/allowedDirectAccessUrls';
import { CustomValidator } from '../../utils/custom-validators';
import BlankLink from '../../basics/BlankLink';
import {
  isApp, isIphone, isAndroid, isIPad,
} from '../../utils/device';

interface IUrgentNotice {
  urgent: {
    notice: string[];
  };
}

let cognitoUser: CognitoUser;
let sessionUserAttributes: any;

// X001
const Login: React.FC = () => {
  const dispatch: AppDispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const { stylePattren } = useSelector((state: RootState) => state.pattern);
  const {
    loginValidationError,
  } = useSelector((state: RootState) => state.xLogin);
  const {
    updateLoginValidationError,
    clearLoginValidationError,
    updateCognitoUserInfo,
  } = xSlice.actions;

  const {
    loggedIn,
    deviceInfo,
    memberInfo,
    loginInfo,
  } = useSelector((state: RootState) => state.user);

  const {
    updateLoggedIn,
    updateDeviceInfo,
    updateMemberInfo,
    updateLoginInfo,
    clearUserInfoInfo,
  } = userSlice.actions;

  const { updateLastLoginDateTime } = topSlice.actions;

  const unmountRef = useUnmountRef();
  const [webId, setWebId] = useSafeState(unmountRef, '');
  const [password, setPassword] = useSafeState(unmountRef, '');
  const [errorMessage, setErrorMessage] = useSafeState(unmountRef, '');
  const [view, setView] = useState(false);
  const proc = useRef(false);
  const customLastLoginDateTime = process.env.REACT_APP_COGNITO_CUSTOM_ATTRIBUTE_1;
  const customFailureCount = process.env.REACT_APP_COGNITO_CUSTOM_ATTRIBUTE_2;
  const [stateUrgentNoticeJson, setStateUrgentNoticeJson] = useState<IUrgentNotice>({
    urgent: {
      notice: [],
    },
  });

  useLayoutEffect(() => {
    if (stylePattren !== 'A') {
      dispatch(updateStylePattern('A'));
    }
  }, []);

  useEffect(() => {
    initiate();
    loadUrgentNotice();
    return () => {
      proc.current = false;
      setView(false);
    };
  }, []);

  const initiate = async () => {
    // ローカルストレージがクリアされるため事前にapplication_bannerを取得する
    const localApplicationBannerLifespan = localStorage.getItem('application_banner_lifespan');
    // ログイン画面遷移時、ログイン関連のユーザー情報をクリアする
    dispatch(updateLoggedIn(false));
    dispatch(clearUserInfoInfo());
    // セッションストレージ、ローカルストレージのクリア、AWS のサインアウト
    sessionStorage.clear();
    localStorage.clear();
    await AwsAuthService.signOut();
    // ローカルストレージがクリアされるため事前にapplication_bannerを取得したものを再設定する
    if (localApplicationBannerLifespan) {
      localStorage.setItem('application_banner_lifespan', `${new Date(localApplicationBannerLifespan)}`);
    }
  };

  const nextUrl = () => {
    const next = new URLSearchParams(location.search).get('next');
    if (next !== null && isAllowedUrl(next)) {
      return next;
    }
    return null;
  };

  const loadUrgentNotice = () => {
    const loadJson = async () => {
      const res = await fetch('/notice/urgent.json');
      const jsonData = await res.json();
      setStateUrgentNoticeJson(jsonData);
    };
    loadJson();
  };

  /**
   * ログインボタン押下処理
   */
  const handleClickLogin = async () => {
    if (proc.current === true) return;

    dispatch(isLoading(true));
    setView(true);
    proc.current = true;

    // バリデーション
    if (!confirmValidation()) {
      return;
    }

    try {
      await cognitoLogin();
    } catch (error) {
      handleErrors(error);
    }
  };

  /**
   * ログイン認証成功後の画面遷移処理
   */
  const pageTransition = () => {
    if (loginInfo.和解客区分 === '1' || loginInfo.ログイン停止区分 === '1') {
      return urls.Q001;
    }
    return nextUrl() || urls.TOP;
  };

  /**
   * Validationを実施する
   */
  const confirmValidation = () => {
    dispatch(clearLoginValidationError());
    // バリデーション
    const validPramas: { ログインID: string, パスワード: string, [key: string]: string } = {
      ログインID: webId,
      パスワード: password,
    };
    try {
      throwIfJoiValidationError(allLoginValidator(validPramas, { abortEarly: false }));
    } catch (err) {
      handleValidationError(err);
      dispatch(isLoading(false));
      proc.current = false;
      setView(false);
      return false;
    }

    // Joi のバリデーションを通過した場合にカスタムバリデーションを実施
    return customValidations();
  };

  /**
   * パスワードのカスタムバリデーション
   * @returns boolean
   */
  const customValidationPassword = () => {
    // カスタムバリデーション
    const customValidator = new CustomValidator(password);

    // パスワードの入力バリデーション
    const passwordError = customValidator.tripleOver()
      .checkNotSupportSymboles()
      .checkBetweenLength(8, 16, '8文字以上16文字以内')
      .inputPolicyCheck()
      .getLastErrorMessage();
    if (passwordError !== '') {
      dispatch(updateLoginValidationError({
        ...loginValidationError,
        パスワード: passwordError,
      }));
      dispatch(isLoading(false));
      setView(false);
      return false;
    }

    return true;
  };

  /**
   * カスタムバリデーションの実施
   * @returns boolean
   */
  const customValidations = () => {
    if (!customValidationPassword()) {
      return false;
    }
    return true;
  };

  /**
   * Cognitoへの認証処理。
   */
  const cognitoLogin = async () => {
    const awsAuthService = new AwsAuthService();
    const authenticationDetails = new AuthenticationDetails({
      Username: webId,
      Password: password,
      ClientMetadata: { authKey: webId },
    });
    cognitoUser = new CognitoUser({
      Username: webId,
      Pool: awsAuthService.userPool,
      Storage: sessionStorage,
    });
    cognitoUser.setAuthenticationFlowType(AwsAuthService.AUTH_FLOW.USER_SRP_AUTH);
    cognitoUser.authenticateUser(authenticationDetails, {
      async onSuccess(session) {
        // 認証成功
        checkLoginUser(session)
          .catch((err) => {
            handleErrors(err);
          });
        // ログイン失敗回数クリア
        await setFailureCount(await AwsAuthService.getUsername(), 0);
      },
      async onFailure(err: any) {
        // 認証失敗
        if (err.message === 'Incorrect username or password.'
          || err.message === 'Missing required parameter USERNAME'
          || err.message === 'PreAuthentication failed with error Incorrect username or password (4016).'
        ) {
          setErrorMessage(Message.vM047('IDまたはパスワード'));
          // アカウントロックのチェック
          await checkAccountLock(webId);
        } else if (err.message === 'Password attempts exceeded') {
          // アカウントロックのチェック
          await checkAccountLock(webId);
        }

        proc.current = false;
        setView(false);
        dispatch(isLoading(false));
      },
      newPasswordRequired(userAttributes: any) {
        // 初回ログインの仮パスワード変更時
        sessionUserAttributes = userAttributes;
        delete sessionUserAttributes.email_verified;
        history.push({ pathname: urls.X002, state: [webId, password] });
      },
    });
  };

  /**
   * ログインユーザーの情報と端末情報を確認する。
   */
  const checkLoginUser = async (session: CognitoUserSession) => {
    // IEのトークが取得できない問題の対応
    // dispatch(updateMemberInfo({ ...memberInfo, memberNo: cognitoUser?.getUsername(), token: session.getIdToken().getJwtToken() }));
    dispatch(updateMemberInfo({ ...memberInfo, memberNo: await AwsAuthService.getUsername(), token: session.getIdToken().getJwtToken() }));
    const apiCommon: ApiCommon = new ApiCommon('X001', 'X-02', urls.LOGIN, '');
    const isGetBlackbox = await apiCommon.updateBlackbox();
    // if (!isGetBlackbox) {
    //   // blackbox 情報を取得できていない場合
    //   history.push(urls.Z001, { message: Message.wM065, code: '5000', isLogin: true });
    // }
    // ログイン ID とパスワードを一時的に保存する
    dispatch(updateTempLoginInfo({
      tempLoginId: webId,
      tempPassword: password,
    }));

    const commonPart = await apiCommon.getLoginCommonPart('', '', await AwsAuthService.getUsername(), await AwsAuthService.getIdToken());
    const dataPart = await apiCommon.getLoginInfoDataPart(webId, password);
    const json = await McsBackendService.request(Object.assign(commonPart, dataPart));
    dispatch(updateDeviceInfo({ ...deviceInfo, distributionId: json.共通部.配信ID, fromUrl: urls.X002 }));
    dispatch(updateViewPattern(json.共通部.ABテストパターン判定));
    dispatch(updateLoginInfo(json.データ部));

    // Cognito に保存されている customLastLoginDateTime を取得しステートに保持
    const lastLoginDate = await getLastLoginDateTime();

    // Cognito のユーザー情報を保持しておく
    dispatch(updateCognitoUserInfo({
      memberId: await AwsAuthService.getUsername(),
      userName: webId,
      lastLoginDate,
    }));

    // 処理日時を Cognito に保存
    await setLastLoginDateTime(json.共通部.処理日時);

    proc.current = false;
    setView(false);
    dispatch(isLoading(false));
    dispatch(updateLoggedIn(true));
  };

  /**
   * 前回ログイン時間を取得する。
   */
  const getLastLoginDateTime = async (): Promise<string> => {
    try {
      const res = await CognitoBackendService.request({
        body: {
          username: await AwsAuthService.getUsername(),
        },
        endpoint: '/getUser',
      });

      for (let i = 0; i < res.UserAttributes.length; i += 1) {
        const element = res.UserAttributes[i];
        if (element.Name === customLastLoginDateTime) {
          dispatch(updateLastLoginDateTime(element.Value));
          return element.Value;
        }
      }
    } catch (err) {
      handleErrors(err);
    }

    return '';
  };

  /**
   * cognitoの前回ログイン時間を更新する。
   */
  const setLastLoginDateTime = async (time: string) => {
    try {
      await CognitoBackendService.request({
        body: {
          username: await AwsAuthService.getUsername(),
          customAttributeValue: time || '',
          customAttribute: customLastLoginDateTime,
        },
        endpoint: '/changeCustomAttribute',
      });
    } catch (err) {
      handleErrors(err);
    }
  };

  /**
   * アカウントロックの判定
   * @param username
   */
  const checkAccountLock = async (username: string) => {
    const memberId = await findCognitoUser(username);
    const failureCount = await getFailureCount(username);
    const nextFailureCount = failureCount + 1;
    const currentFailureCount = await setFailureCount(username, nextFailureCount);
    // ログイン失敗回数が 3 回に達した場合はユーザーを削除する
    if (currentFailureCount >= 3) {
      await deleteAcountNotify(memberId);
      await deleteCognitoUser(memberId);
      history.push(urls.X006);
      // } else {
      //   // ログイン失敗回数をステートに保存
      //   dispatch(updateLoginFailureCount(failureCount.toString()));
    }
  };

  /**
   * Cognito ユーザーを検索
   * @param username
   * @returns
   */
  const findCognitoUser = async (username: string) => {
    let memberId = '';
    try {
      const result = await CognitoBackendService.request({
        body: {
          preferredUsername: username,
        },
        endpoint: '/getListUser',
      });
      for (let i = 0, l = result.Users.length; i < l; i += 1) {
        const user = result.Users[i];
        for (let j = 0, m = user.Attributes.length; j < m; j += 1) {
          const element = user.Attributes[j];
          if (element.Name === 'preferred_username') {
            if (element.Value === username) {
              memberId = user.Username;
              break;
            }
          }
        }
        if (memberId !== '') {
          break;
        }
      }
    } catch (err) {
      handleErrors(err);
    }
    return memberId;
  };

  /**
   * Cognito ユーザーを検索し、現在のログイン失敗回数を取得する。
   */
  const getFailureCount = async (username: string) => {
    let customFailureCountValue = '0';
    try {
      const result = await CognitoBackendService.request({
        body: {
          preferredUsername: username,
        },
        endpoint: '/getListUser',
      });

      for (let i = 0, l = result.Users.length; i < l; i += 1) {
        const user = result.Users[i];
        for (let j = 0, m = user.Attributes.length; j < m; j += 1) {
          const element = user.Attributes[j];
          if (element.Name === customFailureCount) {
            customFailureCountValue = element.Value || '0';
          }
        }
      }
      // dispatch(updateLoginFailureCount(customFailureCountValue));
    } catch (err) {
      handleErrors(err);
    }
    return parseInt(customFailureCountValue, 10);
  };

  /**
   * Cognitoのログイン失敗回数をセットする
   */
  const setFailureCount = async (username: string, failureCount: number) => {
    try {
      await CognitoBackendService.request({
        body: {
          username,
          customAttributeValue: failureCount.toString(),
          customAttribute: customFailureCount,
        },
        endpoint: '/changeCustomAttribute',
      });
      // // ログイン失敗回数をステートに保存
      // dispatch(updateLoginFailureCount(failureCount.toString()));
    } catch (err) {
      handleErrors(err);
    }
    return failureCount;
  };

  /**
   * Cognito ユーザーの削除
   * @param memberId
   * @returns
   */
  const deleteCognitoUser = async (memberId: string) => {
    try {
      await CognitoBackendService.request({
        body: {
          username: memberId,
        },
        endpoint: '/deleteUser',
      });
    } catch (err) {
      handleErrors(err);
    }
  };

  /**
   * 【会員ログイン画面】アカウント削除時の情報連携処理
   */
  const deleteAcountNotify = async (memberId: string) => {
    dispatch(isLoading(true));
    setView(true);

    try {
      const apiCommon: ApiCommon = new ApiCommon('X001', 'X-04', urls.LOGIN, '');
      const commonPart = await apiCommon.getLoginCommonPart('', '', memberId);
      const dataPart = {
        データ部: {
          会員番号: memberId,
        },
      };
      await McsBackendService.request(Object.assign(commonPart, dataPart));
    } catch (err) {
      handleErrors(err);
    } finally {
      dispatch(isLoading(false));
    }
  };

  /**
   * エラー用ハンドラー
   * @param err Error
   */
  const handleErrors = (err: any) => {
    if (err instanceof SfApiException) {
      switch (err.code) {
        // 新規デバイス
        case 'WAG10304':
          dispatch(updateDeviceInfo({ ...deviceInfo, distributionId: err.共通部.配信ID, fromUrl: urls.X002 }));
          history.push(urls.X002);
          break;
        // メンテナンス中
        case 'ESGXX001':
          history.push(urls.X007, { message: err.共通部.エラーメッセージ });
          break;
        default:
          setErrorMessage(err.message);
      }
    } else {
      handleJoiValidationError(err, {
        validationErrorState: loginValidationError,
        updateValidationError: updateLoginValidationError,
      });
    }
    // エラー発生時にローディング状態を解除する
    dispatch(isLoading(false));
  };

  const handleValidationError = (err: any) => {
    handleJoiValidationError(err, {
      validationErrorState: loginValidationError,
      updateValidationError: updateLoginValidationError,
    });
  };

  /**
   * フォーカスを外した際のバリデーション
   * @param name element.name
   * @param value element.value
   */
  const onBlurValidate = async (name: string) => {
    dispatch(updateLoginValidationError({ ...loginValidationError, [name]: '' }));
    setErrorMessage('');
    try {
      switch (name) {
        case 'ログインID':
          throwIfJoiValidationError(idValidator({
            [name]: webId,
          }));
          break;
        case 'パスワード':
          throwIfJoiValidationError(passwordValidator({
            [name]: password,
          }));

          // カスタムバリデーションの実施
          customValidationPassword();
          break;
        default:
          break;
      }
    } catch (err: any) {
      handleValidationError(err);
    }
  };

  const closeApplicationHeaderBanner = () => {
    $('#applicationHeaderBanner').hide();
    localStorage.setItem('application_banner_lifespan', `${new Date()}`);
  };

  const applicationheaderBanner = () => {
    if (!(isAndroid() || isIphone() || isIPad())) {
      return (<></>);
    }
    if (isApp()) {
      return (<></>);
    }

    const localApplicationBannerLifespan = localStorage.getItem('application_banner_lifespan');
    if (localApplicationBannerLifespan) {
      const dt = new Date();
      dt.setMonth(dt.getMonth() + 2);
      if (new Date(localApplicationBannerLifespan) > dt) {
        return (<></>);
      }
    }

    const toAppStore = () => {
      if (isAndroid()) {
        return 'https://play.google.com/store/apps/details?id=jp.co.shinseifinancial.app';
      }
      if (isIphone()) {
        return 'https://apps.apple.com/jp/app/id877483363';
      }
      if (isIPad()) {
        return 'https://apps.apple.com/jp/app/id877483363';
      }
      return '_blank';
    };

    return (
      <>
        <div id="applicationHeaderBanner" className="application_banner" style={{ display: 'block' }}>
          <div className="modaal-container">
            <div className="modaal-content">
              <div className="modaal-content-container">
                <a href={toAppStore()} id="openApplicationStore" rel="noopener noreferrer">
                  <img src="../img/A/X001/app_store_banner.png" alt="レイクアプリのストアページへのリンクバナー" />
                </a>
              </div>
              <button id="modaal-close" className="modaal-close" onClick={closeApplicationHeaderBanner} style={{ display: 'inline-block' }}><span>close</span></button>
            </div>
          </div>
        </div>
      </>
    );
  };

  const urgentNoticeBlock = () => {
    if (!stateUrgentNoticeJson) {
      return (<></>);
    }

    let json: IUrgentNotice = {
      urgent: {
        notice: stateUrgentNoticeJson.urgent.notice.slice(),
      },
    };

    // 緊急告知があれば表示する
    if (json.urgent.notice.length > 0 && json.urgent.notice[0] !== '') {
      const notices = [];
      for (let i = 0, l = json.urgent.notice.length; i < l; i += 1) {
        // 告知文の各要素に対して html を有効にしている（セキュリティ的に少々不安）
        notices.push(<li className="icon--attention" key={i} dangerouslySetInnerHTML={{ __html: json.urgent.notice[i] }} />);
      }
      return (
        <>
          <div className="blockSub blockSub--flat mtM mbM">
            <div className="blockNarrow">
              <div className="card border-red">
                <ul>
                  {notices}
                </ul>
              </div>
            </div>
          </div>
        </>
      );
    }

    return (<></>);
  };

  // パスワードの表示非表示ボタン
  const [showPassword, setShowPassword] = React.useState(false);
  const handleshowPassword = () => {
    if (showPassword) {
      setShowPassword(false);
    } else {
      setShowPassword(true);
    }
  };

  // フッターバナーをクリックしたときに出るアラート
  const alt = () => {
    // eslint-disable-next-line no-alert
    window.alert('これより先はレイク外部のページとなります。');
  };

  return (
    <>
      <LoginHeader />
      <main>
        {applicationheaderBanner()}
        {loggedIn
          ? <Redirect push to={pageTransition()} />
          : (
            <section>
              {urgentNoticeBlock()}
              <div className="blockMain mb05gd">
                <div className="blockNarrow">
                  <h1 className="ttlH1 mtFLAT">会員ログイン</h1>
                  <p className="mbM">
                    はじめてのログイン、再設定はこちら
                  </p>
                  <div className="aR">
                    <Link className="link linkArrowRight" to={urls.X008}>ID/パスワード設定</Link>
                  </div>
                </div>
              </div>
              <div className="blockSub mb05gd">
                <div className="blockNarrow blockNarrow--S">
                  <div className={`mbXL ${(errorMessage) ? 'error-input' : ''}`}>
                    <p className="error-text">
                      {errorMessage && <span className="icon--attention">{errorMessage}</span>}
                    </p>
                    <div className="form__inputBox aC">
                      <div className={`form__inputBox__outer ${webId !== '' ? 'form__inputBox__outer--valid' : ''}`}>
                        <input
                          type="text"
                          minLength={8}
                          maxLength={45}
                          name="ログインID"
                          onChange={(e) => setWebId(e.target.value)}
                          onBlur={(e) => onBlurValidate(e.target.name)}
                        />
                        <span className="form__inputBox__placeholder">ID</span>
                      </div>
                    </div>
                    <div className="flexC">
                      <p className="error-comment error-comment-center">{loginValidationError.ログインID}</p>
                    </div>
                    <div className="form__inputBox aC">
                      <div className={`form__inputBox__outer ${password !== '' ? 'form__inputBox__outer--valid' : ''}`}>
                        <input
                          type={showPassword ? 'text' : 'password'}
                          minLength={8}
                          maxLength={16}
                          name="パスワード"
                          onChange={(e) => setPassword(e.target.value)}
                          onBlur={(e) => onBlurValidate(e.target.name)}
                        />
                        <span className="form__inputBox__placeholder">パスワード（8桁以上）</span>
                        <button className={`form__pwBtn ${showPassword ? 'form__pwBtn--active' : ''}`} onClick={handleshowPassword}>表示切替</button>
                      </div>
                    </div>
                    <div className="flexC">
                      <p className="error-comment error-comment-center">{loginValidationError.パスワード}</p>
                    </div>
                  </div>
                  <div className="aC mbXL">
                    <button className="btn btn--cv" onClick={handleClickLogin}>ログイン</button>
                  </div>
                  <div className="aR mb2XL">
                    <a className="link linkArrowRight" href={createLinkUrlGeneral('user/login/')} target="_blank" rel="noreferrer">会員ログインでお困りの方</a>
                  </div>
                </div>
                <div className="blockNarrow blockNarrow--S">
                  <ul className="linkList mbFLAT">
                    <li className="linkList__item">
                      <a className="linkList__item__link" href={createLinkUrlGeneral('past/')} target="_blank" rel="noreferrer">過去にご利用のあったお客さま</a>
                    </li>
                    <li className="linkList__item">
                      <a className="linkList__item__link" href={createLinkUrlGeneral('info/maintenance/')} target="_blank" rel="noreferrer">メンテナンス情報</a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="blockNarrow">
                <div className="imgBox--center mbM">
                  <BlankLink href="https://www.sbineotrade.jp/landing/sec2/lake.html" onClick={alt}>
                    <img src="/img/A/bn_footer_SBIneo.png" alt="SBIネオトレード証券 魂を込める、証券口座。" className="login_footer_banner" />
                  </BlankLink>
                </div>
              </div>
            </section>
          )}
      </main>
      <Footer />
    </>
  );
};

export default Login;
